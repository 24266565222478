import { takeEvery, put, call, all, delay } from "redux-saga/effects";
import masterActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";
import masterFormsActions from "../masterForms/actions";
import Cookies from "js-cookie";

const masterSagas = function* () {
  yield all([
    yield takeEvery(masterActions.GET_PROJECT_LIST, getProjectList),
    yield takeEvery(masterActions.CREATE_PROJECT, createProject),
    yield takeEvery(masterActions.UPDATE_PROJECT, updateProject),
    yield takeEvery(masterActions.GET_GRIEVANCE, getGrievance),
    yield takeEvery(
      masterActions.BULK_UPLOAD_GENDER_MASTER,
      createGenderBulkUpload
    ),
    yield takeEvery(masterActions.GET_COURSE_LIST, getCourseList),
    yield takeEvery(masterActions.GET_OTBS_COURSE_LIST, getOtbsCourseList),
    yield takeEvery(masterActions.CREATE_COURSE, createCourse),
    yield takeEvery(masterActions.UPDATE_COURSE, updateCourse),
    yield takeEvery(masterActions.SET_ACTIVE_COURSE, setActiveCourse),
    yield takeEvery(masterActions.GET_ALL_BATCH, getAllBatch),
  ]);
};

const getProjectList = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/backoffice/project`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterActions.SET_PROJECT_LIST,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getGrievance = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/grievance`));
    yield put({
      type: masterActions.SET_GRIEVANCE,
      payload: result?.data?.result,
    });
  } catch (err) {
    console.log(err);
  }
};

const createProject = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/project`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterActions.GET_PROJECT_LIST });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateProject = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/backoffice/project/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterActions.GET_PROJECT_LIST });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const createGenderBulkUpload = function* (data) {
  const { payload } = data;
  console.log(payload);
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const formData = new FormData();
    formData.append("genderfile", payload.data.genderfile);
    formData.append("status", payload.data.status);
    console.log(payload.data.genderfile);
    formData.append("updateby", payload.data.updateby);
    formData.append("createdby", payload.data.createdby);

    const result = yield call(() =>
      axios.post(`${API_URL}/bulkupload/gender-master`, payload.data)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_SHOW_BULK_UPLOAD_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
      yield put({ type: masterFormsActions.GET_GENDER });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: "gender", method: "C", data: null },
      });
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getCourseList = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/coursemanagement`)
    );
    let finalResult = result?.data?.result
      ?.sort((a, b) => a?.courseid - b?.courseid)
      ?.map((val, i) => {
        return { ...val, id: i + 1 };
      })
      ?.reverse();
    yield put({
      type: masterActions.SET_COURSE_LIST,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getOtbsCourseList = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/coursemanagement/getotbscourselist`)
    );
    let finalResult = result?.data?.result
      ?.sort((a, b) => a?.courseid - b?.courseid)
      ?.map((val, i) => {
        return { ...val, id: i + 1 };
      })
      ?.reverse();
    yield put({
      type: masterActions.SET_OTBS_COURSE_LIST,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
  } catch (err) {
    console.log(err);
  }
};

const createCourse = function* (data) {
  const { payload } = data;
  const { resetForm,navigate } = payload;
  const formData = new FormData();

  for (let key in payload?.data) {
    formData.append(key, payload?.data[key]);
  }
  // formData.append(
  //   "coursecategoryid",
  //   parseInt(payload?.data?.coursecategoryid)
  // );
  // formData.append("coursename", payload?.data?.coursename);
  // formData.append("regstartdate", payload?.data?.regstartdate);
  // formData.append("regenddate", payload?.data?.regenddate);
  // formData.append("prerequisite", payload?.data?.prerequisite);
  // formData.append("userid", parseInt(payload?.data?.userid));
  // formData.append("coursedriveid", parseInt(payload?.data?.coursedriveid));
  // formData.append("brochurelocation", payload?.data?.brochurelocation);
  if (payload?.data?.document?.file !== null) {
    formData.append("document", payload?.data?.document?.file);
  }

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/coursemanagement`, formData)
    );
    // console.log(result,'result create course')
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      navigate(
        `/${Cookies.get("mhet_mst_project")}/course-management?page=courselist`
      );
      yield put({ type: masterActions.GET_COURSE_LIST });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCourse = function* (data) {
  const { payload } = data;
  const { resetForm, navigate } = payload;
  const formData = new FormData();

  for (let key in payload?.data) {
    formData.append(key, payload?.data[key]);
  }
  // formData.append(
  //   "coursecategoryid",
  //   parseInt(payload?.data?.coursecategoryid)
  // );
  // formData.append("coursename", payload?.data?.coursename);
  // formData.append("regstartdate", payload?.data?.regstartdate);
  // formData.append("regenddate", payload?.data?.regenddate);
  // formData.append("prerequisite", payload?.data?.prerequisite);
  // formData.append("userid", parseInt(payload?.data?.userid));
  // formData.append("coursedriveid", parseInt(payload?.data?.coursedriveid));
  // formData.append("isactive", parseInt(payload?.data?.isactive));
  // formData.append("brochurelocation", payload?.data?.brochurelocation);
  // console.log(payload?.data?.document?.file);
  if (payload?.data?.document?.file !== null) {
    formData.append("document", payload?.data?.document?.file);
  }

  console.log(payload.data, formData, "sagas", payload?.data?.courseid);
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/coursemanagement/${payload?.data?.courseid}`,
        formData
      )
    );
    // console.log(result,'result create course')
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      navigate(
        `/${Cookies.get("mhet_mst_project")}/course-management?page=courselist`
      );
      yield put({ type: masterActions.GET_COURSE_LIST });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const setActiveCourse = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/coursemanagement/updateactive/${payload?.data?.courseid}`,
        payload?.data
      )
    );
    // console.log(result,'result Update active course')
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      yield put({ type: masterActions.GET_COURSE_LIST });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getAllBatch = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/batch`));
    let finalResult = result?.data?.result
      ?.sort((a, b) => a?.courseid - b?.courseid)
      ?.map((val, i) => {
        return { ...val, id: i + 1 };
      })
      ?.reverse();
    yield put({
      type: masterActions.SET_ALL_BATCH,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

export default masterSagas;
