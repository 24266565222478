import courseActions from "./actions";
import { put, call, takeEvery, all, delay } from "redux-saga/effects";
import { API_URL } from "../../utils/constants";
import axios from "axios";
import commonActions from "../common/actions";
import Cookies from "js-cookie";
import masterActions from "../master/actions";

const courseSagas = function* () {
  yield all([
    yield takeEvery(courseActions.GET_COURSETAB_MASTER, getCourseTabMaster),
    yield takeEvery(courseActions.GET_COURSEFIELD_MASTER, getCourseFieldMaster),
    yield takeEvery(courseActions.CREATE_COURSEFORM, createCourseForm),
  ]);
};

const getCourseTabMaster = function* (data) {
  const { payload } = data;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/coursetab/${payload?.courseid}`)
    );
    if (result?.data?.statusCode === 200) {
      let res = result?.data?.result
        ?.filter((val) => {
          if (parseInt(val?.status) === 1) {
            return val;
          }
        })
        ?.map((val) => {
          return {
            ...val,
            isActive: val?.isactive === "1",
            selectAll: false,
            mandatorySelectAll: false,
          };
        });
      yield put({ type: courseActions.SET_COURSETAB_MASTER, payload: res });
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCourseFieldMaster = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/coursefields/${payload?.courseid}`)
    );
    if (result?.data?.statusCode === 200) {
      console.log(result?.data?.result, "result?.data?.result");
      let res = result?.data?.result?.map((val) => {
        return {
          ...val,
          isSelected: val?.isselected === "1",
          fieldlabel:
            val?.fieldlabel && val?.fieldlabel !== ""
              ? val?.fieldlabel
              : val?.fieldname,
          fieldlabelerror: false,
          ismandatory: val?.ismandatory === "1",
          isreadonly: val?.isreadonly === "1",
        };
      });
      let indexToRemove = res?.findIndex(
        (val) => val?.fieldname === "Same as Permanent Address"
      );
      let newIndex = res.findIndex(
        (val) => val?.fieldname === "Communication Address Line 1"
      );

      let [removedObject] = res.splice(indexToRemove, 1);

      // Insert the object at the new index
      res.splice(newIndex - 1, 0, removedObject);

      yield put({ type: courseActions.SET_COURSEFIELD_MASTER, payload: res });
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createCourseForm = function* (data) {
  const { payload } = data;
  const { navigate, handleClose } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/coursemanagement/form`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      handleClose();
      navigate(
        `/${Cookies.get("mhet_mst_project")}/course-management?page=courselist`
      );
      yield put({ type: masterActions.GET_COURSE_LIST });
    }
    //   if (result?.data?.statusCode === 200) {
    //     resetForm();
    //     yield put({ type: masterFormsActions.GET_COURSEFIELD });
    //     yield put({
    //       type: commonActions.SET_SHOW_MODAL,
    //       payload: { show: false, type: null, method: null, data: null },
    //     });
    //   }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

export default courseSagas;
