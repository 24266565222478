import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Grid, Radio, TextField, Typography } from "@mui/material";
import React,{useEffect} from "react";
import { MdExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import courseActions from "../../redux/course/actions";
import { cssProperties } from "../../utils/commonCssProperties";

export default function CourseTabAndFields() {

    const dispatch = useDispatch();

    const { courseTabMaster , courseFieldMaster } = useSelector(state => state.courseReducer);

console.log('courseTabMaster---',courseTabMaster);
console.log('courseFieldMaster---',courseFieldMaster);


//  const setTabFieldData = ()=>{
//     console.log("func called",courseTabMaster,courseFieldMaster)
//     if(courseTabMaster.length > 0 && courseFieldMaster.length > 0){
//         let array = [...courseTabMaster];
//         // array[index].isActive = value;
//         console.log(array,'array')

//         for(let k = 0; k < array?.length; k++){
//             array[k].isActive = array[k].isactive === '1';
//             let array2 = [...courseFieldMaster];
//             for (let i = 0; i < array2?.length; i++) {
//                 if (array2[i]?.coursetabid === array[k].coursetabid) {
//                     array2[i].isSelected = array[k].coursetabid;
//                     array2[i].fieldlabel = array2[k].fieldname;
//                     array2[i].fieldlabelerror = false;
//                     array2[i].ismandatory = false;
//                     array2[i].isreadonly = false;
//                 }
//             };
//             dispatch({ type: courseActions.SET_COURSEFIELD_MASTER, payload: array2 });
//             // array[k].selectAll = false;
//             // array[k].mandatorySelectAll = false;
//         }
//         console.log(array,'array')
        
//         dispatch({ type: courseActions.SET_COURSETAB_MASTER, payload: array });
//     }
//  }
// useEffect(()=>{
//     setTimeout(()=>{
//         setTabFieldData()
        
//     },3000)
// },[])
    const handleCourseTabChange = (index, value, tabid) => {
        let array = [...courseTabMaster];
        array[index].isActive = value;
        if (value === false) {
            let array2 = [...courseFieldMaster];
            for (let i = 0; i < array2?.length; i++) {
                if (array2[i]?.coursetabid === tabid) {
                    array2[i].isSelected = false;
                    array2[i].fieldlabel = array2[i].fieldname;
                    array2[i].fieldlabelerror = false;
                    array2[i].ismandatory = false;
                    array2[i].isreadonly = false;
                }
            };
            dispatch({ type: courseActions.SET_COURSEFIELD_MASTER, payload: array2 });
            array[index].selectAll = false;
            array[index].mandatorySelectAll = false;
        }
        dispatch({ type: courseActions.SET_COURSETAB_MASTER, payload: array });
    };

    const handleSelectAll = (e, tabid) => {
        let isChecked = e.target.checked;
        let array = [...courseFieldMaster];
        for (let i = 0; i < array?.length; i++) {
            if (array[i]?.coursetabid === tabid) {
                array[i].isSelected = isChecked;
                if (!isChecked) {
                    array[i].fieldlabel = array[i].fieldname;
                    array[i].fieldlabelerror = false;
                    array[i].ismandatory = false;
                    array[i].isreadonly = false;
                }
            }
        };
        dispatch({ type: courseActions.SET_COURSEFIELD_MASTER, payload: array });
        let tabarray = [...courseTabMaster];
        let tabind = tabarray?.findIndex(val => val?.coursetabid === tabid);
        if (tabind !== -1) {
            tabarray[tabind].selectAll = isChecked;
            if (!isChecked) {
                tabarray[tabind].mandatorySelectAll = isChecked;
            }
        }
        dispatch({ type: courseActions.SET_COURSETAB_MASTER, payload: tabarray });
    };

    const handleMandatorySelectAll = (e, tabid) => {
        let isChecked = e.target.checked;
        let array = [...courseFieldMaster];
        for (let i = 0; i < array?.length; i++) {
            if (array[i]?.coursetabid === tabid) {
                array[i].ismandatory = isChecked;
                // if (!isChecked) {
                //     array[i].fieldlabel = array[i].fieldname;
                //     array[i].fieldlabelerror = false;
                //     array[i].ismandatory = false;
                //     array[i].isreadonly = false;
                // }
            }
        };
        dispatch({ type: courseActions.SET_COURSEFIELD_MASTER, payload: array });
        let tabarray = [...courseTabMaster];
        let tabind = tabarray?.findIndex(val => val?.coursetabid === tabid);
        if (tabind !== -1) {
            tabarray[tabind].mandatorySelectAll = isChecked;
        }
        dispatch({ type: courseActions.SET_COURSETAB_MASTER, payload: tabarray });
    };

    const handleSelectField = (e, fieldid) => {
        let isChecked = e.target.checked;
        let array = [...courseFieldMaster];
        let fieldindex = array.findIndex(val => val?.coursefieldid === fieldid);
        array[fieldindex].isSelected = isChecked;
        if (isChecked) {
            let childarray = array.filter((c) => {
                if (parseInt(c.parentfieldid) === parseInt(fieldid)) {
                    return c;
                }
            })?.map(ca => { return ca.coursefieldid });
            if (childarray?.length > 0) {
                for (let i = 0; i < childarray?.length; i++) {
                    let childindex = array.findIndex(cf => cf.coursefieldid === childarray[i]);
                    array[childindex].isSelected = isChecked;
                }
            };
        } else {
            array[fieldindex].fieldlabel = array[fieldindex].fieldname;
            array[fieldindex].fieldlabelerror = false;
            array[fieldindex].ismandatory = false;
            array[fieldindex].isreadonly = false;
        }
        dispatch({ type: courseActions.SET_COURSEFIELD_MASTER, payload: array });
    };

    const handleFieldLabelChange = (e, fieldid) => {
        let array = [...courseFieldMaster];
        let fieldindex = array.findIndex(val => val?.coursefieldid === fieldid);
        array[fieldindex].fieldlabel = e.target.value;
        array[fieldindex].fieldlabelerror = false;
        if (e.target.value?.length === 0) {
            array[fieldindex].fieldlabelerror = true;
        };
        dispatch({ type: courseActions.SET_COURSEFIELD_MASTER, payload: array });
    };

    const handleMandatoryChange = (e, fieldid) => {
        let isChecked = e.target.checked;
        let array = [...courseFieldMaster];
        let fieldindex = array.findIndex(val => val?.coursefieldid === fieldid);
        array[fieldindex].ismandatory = isChecked;
        dispatch({ type: courseActions.SET_COURSEFIELD_MASTER, payload: array });
    };

    const handleReadonlyChange = (e, fieldid) => {
        let isChecked = e.target.checked;
        let array = [...courseFieldMaster];
        let fieldindex = array.findIndex(val => val?.coursefieldid === fieldid);
        array[fieldindex].isreadonly = isChecked;
        dispatch({ type: courseActions.SET_COURSEFIELD_MASTER, payload: array });
    };

    return (
        <>
            {courseTabMaster?.length > 0 &&
                <>
                    {courseTabMaster?.map((val, i) =>
                        <Accordion key={i}>
                            <AccordionSummary expandIcon={<MdExpandMore />} id={val?.tabname}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        pr: 3,
                                    }}
                                >
                                    <Typography>{val?.tabname}</Typography>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    value="Yes"
                                                    sx={{ margin: 0, padding: 0 }}
                                                    checked={val?.isActive }
                                                    onChange={(e) => handleCourseTabChange(i, true, val?.coursetabid)}
                                                />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    value="No"
                                                    sx={{ margin: 0, padding: 0 }}
                                                    checked={!val?.isActive}
                                                    onChange={(e) => handleCourseTabChange(i, false, val?.coursetabid)}
                                                />
                                            }
                                            label="No"
                                        />
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container p={1}>
                                    <Grid item md={8} xs={12}>
                                        {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={!val?.isActive}
                                                    sx={{ margin: 0, padding: 0 }}
                                                    checked={val?.selectAll}
                                                    onChange={(e) => handleSelectAll(e, val?.coursetabid)}
                                                />
                                            }
                                            label={<span style={{ fontWeight: "bold" }}>Select All</span>}
                                        /> */}
                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        sx={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <Typography>Mandatory (Y/N)</Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        sx={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <Typography>Existing data(Y/N)</Typography>
                                        {/* <Typography>Read only(Y/N)</Typography> */}
                                    </Grid>
                                </Grid>
                                <Grid container p={1}>
                                    <Grid item md={8} xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={!val?.isActive}
                                                    sx={{ margin: 0, padding: 0 }}
                                                    checked={val?.selectAll}
                                                    onChange={(e) => handleSelectAll(e, val?.coursetabid)}
                                                />
                                            }
                                            label={<span style={{ fontWeight: "bold" }}>Select All</span>}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        sx={{ display: "flex", justifyContent: "flex-end" }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={!val?.isActive}
                                                    sx={{ margin: 0, padding: 0 }}
                                                    checked={val?.mandatorySelectAll}
                                                    onChange={(e) => handleMandatorySelectAll(e, val?.coursetabid)}
                                                />
                                            }
                                            label={<span style={{ fontWeight: "bold" }}>Select All</span>}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        sx={{ display: "flex", justifyContent: "center" }}
                                    >

                                    </Grid>
                                </Grid>
                                {courseFieldMaster?.filter((ff) => {
                                    if (val?.coursetabid === ff?.coursetabid) {
                                        return ff;
                                    }
                                })?.map((field, ind) =>
                                    <Grid container p={1} key={ind} mt={1}>
                                        <Grid item md={4} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={!val?.isActive}
                                                        checked={field.isSelected}
                                                        onChange={(e) => handleSelectField(e, field?.coursefieldid)}
                                                        sx={{ margin: 0, padding: 0 }}
                                                    />
                                                }
                                                label={field.fieldname}
                                                sx={{
                                                    alignItems: 'flex-start',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                value={field.fieldlabel}
                                                disabled={!val?.isActive || !field.isSelected}
                                                onChange={(e) => handleFieldLabelChange(e, field?.coursefieldid)}
                                                size="small"
                                            />
                                            {field?.fieldlabelerror &&
                                                <Typography variant="body2" sx={{ color: `${cssProperties?.color?.failure}` }}>required</Typography>
                                            }
                                        </Grid>

                                        <Grid
                                            item
                                            md={2}
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{ margin: 0, padding: 0 }}
                                                        disabled={!val?.isActive || !field.isSelected}
                                                        checked={field?.ismandatory}
                                                        onChange={(e) => handleMandatoryChange(e, field?.coursefieldid)}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={2}
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{ margin: 0, padding: 0 }}
                                                        disabled={!val?.isActive || !field.isSelected}
                                                        checked={field?.isreadonly}
                                                        onChange={(e) => handleReadonlyChange(e, field?.coursefieldid)}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </>
            }
        </>
    )
};