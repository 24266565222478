import masterActions from "./actions";

const initialState = {
  projectList: {
    all: [],
    filtered: [],
    grievance: [],
  },
  courseList:{
    all:[],
    filtered:[]

  },
  batchList:{
    all:[],
    filtered:[]
  },
  otbsCourseList:{all:[],filtered:[]}
};

const masterReducer = (state = initialState, action) => {
  switch (action.type) {
    case masterActions.SET_PROJECT_LIST:
      return {
        ...state,
        projectList: action.payload,
      };
    case masterActions.SET_GRIEVANCE:
      return {
        ...state,
        grievance: action.payload,
      };
      case masterActions.SET_COURSE_LIST:
      return {
        ...state,
        courseList: action.payload,
      };
      case masterActions.SET_ALL_BATCH:
        return {
          ...state,
          batchList: action.payload,
        };
        case masterActions.SET_OTBS_COURSE_LIST:
          return {
            ...state,
            otbsCourseList:action.payload
          }
    default:
      return state;
  }
};

export default masterReducer;
